import {Pipe, PipeTransform} from "@angular/core";
import {Notification} from "../../api/models/notification";
import {UserRole} from "../../api/models/user-role";

export function routeOfExpectedValidationNotification(notification: Notification): any[] {
  if (notification.type !== 'VALIDATION_EXPECTED') {
    throw new Error(`only supports notification type 'VALIDATION_EXPECTED', received : ${notification.type}`);
  }
  if (!notification.role) {
    throw new Error(`Expected validation notification must have a defined role`);
  }
  return [`/purchase-order/${notification.purchaseOrder!.uuid}/validation/${validationFromRole(notification.role)}`]
}

function validationFromRole(role: UserRole): string {
  if (role === "QHSE") {
    return 'green-lights'
  }
  if (role === "DIRECTOR") {
    return 'amount'
  }
  throw new Error(`Notification role is not supported : ${role}`);
}

@Pipe({
  name: 'routeOfExpectedValidationNotification',
  standalone: true
})
export class RouteExpectedValidationNotificationPipe implements PipeTransform {
  transform(notification: Notification): any[] {
    return routeOfExpectedValidationNotification(notification);
  }

}
